@import '../../../../variables';
.sugar-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    .sugar-container{
        max-width: $appmaxwidth;
        width: 100%;
        height: 100%;
        position: relative; 
        background-color: $bgcolor;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 
        text-align: center;
        background-size: cover;
        background-image: url(../../../../images/Layer5.png);
        >h2{
            color: #fff;
            margin-bottom: 40px;
            font-size: 36px;
        }
        >p{
            color: #fff;
            line-height: 22px;
            width: 60%;
            margin: 50px 0;
        }
        >div{
            width: 80%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}