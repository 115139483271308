@import "../../variables";
.footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 68px;
  background-color: #9a32e8;
  > .footer-container {
    width: 100%;
    height: 100%;
    max-width: $appmaxwidth;
    display: flex;
    align-items: center;
    justify-content: space-around;
    > img {
      width: 100px;
    }
    > div {
      width: 30%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      > p {
        color: #fff;
        margin: 0;
        font-size: 14px;
      }
      > a {
        color: #fff;
        text-decoration: none;
        font-size: 14px;
      }
      > div {
        justify-content: space-between;
        img {
          margin: 5px;
        }
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .footer-wrapper {
    height: max-content;
    > .footer-container {
      display: flex;
      flex-direction: column;
      padding: 10px;
      > div {
        display: flex;
        flex-direction: column;
        width: 100%;
        > a {
          margin: 2px;
        }
      }
    }
  }
}
