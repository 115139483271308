@import '../../variables';
.navbar-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 500;
    height: 100px;
    >.navbar-container {
        width: 100%;
        max-width: $appmaxwidth;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding: 0 150px;
        img{
            width: 150px;
        }
        >div{
            display: flex;
            >a{
                font-size: 16px;
                color: #fff;
                padding: 0 15px;
                text-decoration: none;
                margin-top: 11px;
            }
            >a:hover{
                transform: scale(1.2);
            }
            >div{
                background-color: transparent;
                background-size: 50% 200%;
                background-position:left bottom;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-right: 10px;
                padding: 10px;
                >a{
                    font-size: 16px;
                    color: #fff;
                    text-decoration: none;
                }
                >img{
                    width: 6px;
                    margin-left: 10px;
                    margin-top: 2px;
                }
            }
            >div:hover {
                background: linear-gradient(286deg, #7c14ca 35%, rgb(173, 103, 226) 100%);
                background-size: 200% 50%;
                background-position: bottom left;
                transition: all .9s ease-out;
            }
        }
    }
}
.navbar-wrapper.active {
    position: fixed;
    .navbar-container{
        >a{
            >img{
                display: none;
            }
        }
    }
  }

@media screen and (max-width: 991px) {
    .navbar-wrapper{
        display: none;
    }
}