@import '../../variables';
.faq-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    .faq-container{
        max-width: $appmaxwidth;
        width: 100%;
        height: 100%;
        position: relative; 
        background-color: $bgcolor;
        display: flex;
        justify-content: center;
        align-items: center; 
        text-align: center;
        padding: 0;
        padding-bottom: 100px;
        background-image: url(../../images/Layer2.png);
        background-repeat: no-repeat;
        background-size: 50% auto;
        >.faqdiv, .emptydiv{
            color: #fff;
            >h2{
                font-size: 36px;
            }
            .faqcard-wrapper{
                cursor: pointer;
                background: linear-gradient(90deg, #3a0d76 35%, rgba(76,30,137,1) 100%);
                border-radius: 10px;
                border: 3px solid #4e3e79;
                padding: 10px;
                display: flex;
                justify-content: center;
                text-align: left;
                margin: 20px 0;
                width: 100%;
                >.selector-container{
                    width: 10%;
                    height: 100%;
                    text-align: right;
                    color: #8ccffb;                    
                    >p{
                        margin: 0;
                        margin-right: 10px;
                    }
                }
                >.faqs{
                    width: 90%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    >h6{
                        font-size: 14px;
                        margin: 0;
                    }
                    >p{
                        font-size: 13px;
                        margin: 0;
                        margin-top: 10px;
                    }
                }
               
            }
        }
    }
}
@media screen and (min-width: 991px) {
    .faq-container{
        >.faqdiv, .emptydiv{
            width: 50%;
            text-align: left;
            padding-right: 150px;
        }
    }
}
@media screen and (max-width: 991px) {
    .faq-container{
        >.emptydiv{
            display: none;
        }
        >.faqdiv{
            width: 70%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-right: 0;
        }
    }
}