@import '../../variables';
.mobileNavbar-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    top: 20px;
    z-index: 500;
    >.mobileNavbar-container {
        width: 90%;
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        >.top{
            display: flex;
            justify-content: space-between;
            width: 100%;
           
            >img{
                width: 40px;
                margin-top: -30px;
            }
            >a{
                height: 60px;
                >img{
                    width: 150px;
                }
            }
            
        }
        
        >.bottom{
            display: flex;
            flex-direction: column;
            text-align: center;
            height: 80%;
            width: 90%;           
            >a{
                font-size: 16px;
                color: #fff;
                padding: 15px 0;
                text-decoration: none;
                margin-top: 7px;
                background-color: #26125b;
            }
        }
    }
}
.mobileNavbar-wrapper.active {
    position: fixed;
    .mobileNavbar-container{
        >.top{
            >a{
               >img{
                   display: none;
               }
            }
        }
    }
  }
@media screen and (min-width: 991px) {
    .mobileNavbar-wrapper{
        display: none;
    }
}