@import '../../../../variables';
.windows-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-image: url(../../../../images/Wbg.png);
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $bgcolor;
    align-items: center;
    .windows-container{
        max-width: $appmaxwidth;
        width: 100%;
        height: 100%;
        position: relative; 
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 
        text-align: center;
        >h2{
            color: #fff;
            font-size: 36px;
            margin-bottom: 20px;
            line-height: 40px;
        }
        >div{
            width: 70%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}