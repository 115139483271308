.featureCard-wrapper,
.featureCard3-wrapper {
  width: 270px;
  height: 270px;
  background: linear-gradient(90deg, #3a0d76 35%, rgba(76, 30, 137, 1) 100%);
  border-radius: 10px;
  border: 3px solid #4e3e79;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 10px;
  > img {
    width: 80px;
  }
  > div {
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > h2 {
      color: #fff;
      font-size: 1.5rem;
      margin: 0;
    }
  }
}
.featureCard3-wrapper {
  > img {
    width: 60px;
  }
  > div {
    margin-top: 10px;
  }
}
