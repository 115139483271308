.windowsCard-wrapper{
    width: 230px;
    height: 350px;
    border-radius: 10px;
    margin: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 2px solid #6c638e;
    color: #fff;
    background: linear-gradient(90deg, rgba(59,18,114,1) 35%, rgba(80,38,138,1) 100%);
    margin-bottom: 20px;
    >h4, h5{
        margin: 0;
        margin-bottom: 3px;
    }
    >div{
        width: 90%;
        height: 190px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        >div{
            display: flex;
            justify-content: center;
            align-items: center;
            >img{
                width: 10px;
            }
            >p{
                font-size: 10px;
                margin: 3px;
               
            }
        }
    }
    >a{
        margin-top: 10px;
        border-radius: 7px;
        text-decoration: none;
        color: #fff;
        border: none;
        padding: 8px 20px;
        font-size: 10px;
        cursor: pointer;
        background: linear-gradient(90deg, rgba(104,1,181,1) 35%, rgba(164,60,244,1) 100%);
    }
}