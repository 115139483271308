@import '../../variables';
.home-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    align-items: center;
    background-image: url(../../images/bannerbg.png);
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $bgcolor;
    >.home-container{
        max-width: $appmaxwidth;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
       
    } 
}
@media screen and (min-width: 991px) {
.home-container{
    >div{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        >h1{
            width: 50%;
            color: #fff;
            line-height: 50px;
            margin-left: 100px;
        }
        >img{
            width: 50%;
        }
    }
}
}
@media screen and (max-width: 991px) {
    .home-container{
        >div{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 80%;
            >h1{
                width: 100%;
                color: #fff;
                line-height: 50px;
                text-align: center;
                margin-bottom: 30px;
                font-size: 24px;
            }
            >img{
                width: 60%;
            }
        }
    }
}
@media screen and (max-width: 400px) {
    .home-container{
        >div{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 80%;
            >h1{
                width: 100%;
                color: #fff;
                line-height: 50px;
                text-align: center;
                font-size: 24px;
                margin-bottom: 30px;
            }
            >img{
                width: 90%;
            }
        }
    }
}