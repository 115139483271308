@import '../../variables';
.feature-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    .feature-container{
        max-width: $appmaxwidth;
        width: 100%;
        height: 100%;
        background-color: $bgcolor;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 
        >h2{
            color: #fff;
            margin-bottom: 40px;
            font-size: 36px;
        }
        >div{
            width: 70%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}