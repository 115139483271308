@import '../../variables';
.success-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: max-content;
    align-items: center;
    .success-container{
        max-width: $appmaxwidth;
        width: 100%;
        height: 100%;
        position: relative; 
        background-color: $bgcolor;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 
        text-align: center;
        background-size: cover;
        background-image: url(../../images/Layer5.png);
        >h2{
            color: #fff;
            font-size: 36px;
        }
        >p{
            color: #fff;
            line-height: 22px;
            margin-bottom: 20px;
            margin-top: 0;
            padding: 0 20px;
        }
        >iframe{
            width: 70%;
            border-radius: 20px;
            border: 5px solid #66567d;
        }
    }
}
@media screen and (min-width: 991px) {
    iframe{
        height: 800px;
        padding: 15px;
    }
}
@media screen and (max-width: 991px) {
    iframe{
        height: 500px;
        padding: 15px;
    }
}
