.residentialCard-wrapper{
    width: 230px;
    height: 350px;
    border-radius: 10px;
    margin: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 2px solid #d0b0e8;
    color: #fff;
    background: linear-gradient(146deg, rgba(107,6,182,1) 35%, rgba(158,54,237,1) 100%);
    >h2, h4{
        margin: 0;
        margin-bottom: 10px;
    }
    >p{
        font-size: 10px;
        margin: 5px;
        width: 90%;
    }
    >hr{
        width: 80%;
        margin: 0;
        opacity: 0.2;
    }
    >a{
        margin-top: 10px;
        border-radius: 7px;
        text-decoration: none;
        color: #fff;
        border: none;
        padding: 8px 20px;
        font-size: 10px;
        cursor: pointer;
        background: linear-gradient(90deg, rgba(100,31,153,1) 35%, rgba(76,8,127,1) 100%);
    }
}