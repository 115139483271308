.sugarCard-wrapper1, .sugarCard-wrapper2, .sugarCard-wrapper3{
    width: 320px; 
    height: 220px;
    display: flex;
    border-radius: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff; 
    align-items: flex-end;
    margin: 10px;
    >div{
        margin-right: 20px;
        text-align: left;
        >h5{
            font-size: 16px;
            margin: 0;
            margin-bottom: 10px;
        }
        >h6{
            margin-top: 8px;
            margin-bottom: 10px;
            font-size: 12px;
        }
        >p{
            font-size: 12px;
            margin: 0;
        }
        >div{
            width: 80px;
            height: 30px;
            background-color: transparent;
            display: flex;
            align-items: center;
            padding: 5px;
            background-size: 50% 200%;
            background-position:left bottom;
            >a{
                font-size: 14px;
                color: #fff;
                text-decoration: none;
            }
            >img{
                width: 6px;
                margin-left: 10px;
                margin-top: 2px;
            }
        }
        >div:hover {
            background: linear-gradient(286deg, rgba(118,15,196,1) 35%, rgba(196,127,248,1) 100%);
            background-size: 200% 100%;
            background-position: bottom left;
            transition: all .5s ease-out;
        }
    }
}
.sugarCard-wrapper1{
    background-image: url(../../../../../images/sbg1.png);
    background-size: cover;
}
.sugarCard-wrapper2{
    background-image: url(../../../../../images/sbg2.png);
    background-size: cover;
}
.sugarCard-wrapper3{
    background-image: url(../../../../../images/sbg3.png);
    background-size: cover;
}